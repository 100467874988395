import { ISettingsDetails } from './interfaces/ISettingsDetails';

export const INITIAL_SETTINGS_DETAILS: ISettingsDetails = {
    brokerFeeType: 'Fixed',
    brokerFeeAmount: '',
    companyName: '',
    emailAddress: '',
    phoneNumber: '',
    faxNumber: '',
    dispatchSupportNumber: '',
    companyAddress: '',
    city: '',
    state: '',
    zipCode: '',
    companyLogo: '',
    baseSettingId: 0,
    settlementReportBatchNumber: '',
};

export const BROKER_FEE_TYPE = 'BrokerFeeType';
export const BROKER_FEE_AMOUNT = 'BrokerFeeAmount';
export const COMPANY_NAME = 'CompanyName';
export const COMPANY_ADDRESS = 'CompanyAddress';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP_CODE = 'ZipCode';
export const PHONE_NUMBER = 'PhoneNumber';
export const DISPATCH_SUPPORT_NUMBER = 'DispatchSupportNumber';
export const FAX_NUMBER = 'FaxNumber';
export const EMAIL_ADDRESS = 'EmailAddress';
export const COMPANY_LOGO = 'CompanyLogo';
export const SETTLEMENT_REPORT_BATCH_NUMBER = 'SettlementReportBatchNumber';

export const settingConstants = {
    tabs: {
        companyAndUserTab: 'Settings:companyAndUsers',
        brokerAndDriverTab: 'Settings:generalPreferences',
        communicationAndInvoice: 'Settings:communicationAndInvoices',
    },
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { REPORTS_INITIAL_STATE } from './initialState';
import { ITrailerDetail } from './interfaces/ITrailerDetail';

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: REPORTS_INITIAL_STATE,
    reducers: {
        setTrailersList: (state, action: PayloadAction<ITrailerDetail[]>) => {
            state.trailersList = action.payload;
        },
    },
});

export const { setTrailersList } = reportsSlice.actions;

const ReportsReducer = reportsSlice.reducer;
export default ReportsReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelect } from '../../../../utils/interfaces';
import { NEW_DRIVER_INITIAL_STATE } from '../../initialState';
import { IAttachment } from '../../../../api/interfaces';
import { IDriver, IDriverAddress } from '../../../../api/driver/interfaces';
import { IDriverInitialState } from '../../interfaces';

const initialState: IDriverInitialState = {
    loading: false,
    newDriver: NEW_DRIVER_INITIAL_STATE.newDriver,
    truckingCompanyModalDetails:
        NEW_DRIVER_INITIAL_STATE.truckingCompanyModalDetails,
    truckingCompanyModalErrorDetails:
        NEW_DRIVER_INITIAL_STATE.truckingCompanyModalErrorDetails,
    driverTagOptions: [],
};

export const driverSlice = createSlice({
    name: 'driver',
    initialState,
    reducers: {
        setNewDriver: (state, action: PayloadAction<Partial<IDriver>>) => {
            state.newDriver = {
                ...state.newDriver,
                ...action.payload,
            };
        },
        resetNewDriver: state => {
            state.newDriver = NEW_DRIVER_INITIAL_STATE.newDriver;
        },
        setNewDriverAttachments: (
            state,
            action: PayloadAction<IAttachment[]>,
        ) => {
            state.newDriver.attachments = action.payload;
        },
        setNewDriverProfileImage: (
            state,
            action: PayloadAction<IAttachment[]>,
        ) => {
            state.newDriver.profileImage = action.payload;
        },
        setNewDriverAddress: (
            state,
            action: PayloadAction<Partial<IDriverAddress>>,
        ) => {
            state.newDriver.address = {
                ...state.newDriver.address,
                ...action.payload,
            };
        },
        setDriverTagList: (state, action: PayloadAction<ISelect[]>) => {
            state.driverTagOptions = action.payload;
        },
    },
});

export const {
    setNewDriver,
    resetNewDriver,
    setNewDriverAttachments,
    setNewDriverProfileImage,
    setNewDriverAddress,
    setDriverTagList,
} = driverSlice.actions;

const DriverReducer = driverSlice.reducer;
export default DriverReducer;

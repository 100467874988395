export const AttachmentExpiryTypes = {
    expired: 'Expired',
    expiringSoon: 'ExpiringSoon',
};

export const AttachmentTypes = {
    other: 'Other',
    invoice: 'Invoice',
    billOfLading: 'BillOfLading',
};

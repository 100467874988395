import { IReportsInitialState } from './interfaces/IReportsInitialState';

export const REPORTS_INITIAL_STATE: IReportsInitialState = {
    trailersList: [
        {
            trailerNumber: 12345,
            trailerSize: '49K',
            assignedDriver: 'John Smith',
            companyPercent: 5.9,
            rentPercent: 0,
        },
        {
            trailerNumber: 67890,
            trailerSize: '52K',
            assignedDriver: 'Alfredo Stevens',
            companyPercent: 6.5,
            rentPercent: 0,
        },
        {
            trailerNumber: 10293,
            trailerSize: '49K',
            assignedDriver: 'Kelly Trails',
            companyPercent: 2.5,
            rentPercent: 10.0,
        },
        {
            trailerNumber: 84765,
            trailerSize: '55-62K',
            assignedDriver: 'Penelope Vasquez',
            companyPercent: 15.0,
            rentPercent: 5.0,
        },
    ],
};

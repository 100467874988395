import {
    IInvoicePreview,
    IInvoiceLineAdd,
} from '../../../../../../api/shipment/interfaces/IInvoicePreview';

export const INVOICE_LINE_ADD: IInvoiceLineAdd = {
    itemRef: {
        listID: null,
        fullName: '',
    },
    quantity: 0,
    description: '',
    rate: null,
    amount: null,
    driverLoadId: 0,
};

export const INVOICE_PREVIEW_INITIAL_STATE: IInvoicePreview = {
    customerRef: {
        listId: null,
        fullName: '',
    },
    templateRef: {
        listId: null,
        fullName: '',
    },
    invoiceLineAdd: [INVOICE_LINE_ADD],
    txnDate: '',
    dueDate: null,
    termsRef: {
        listId: null,
        fullName: '',
    },
    truck: null,
    contractPO: '',
    order: '',
    invoiceNumber: '',
    totalAmount: 0,
};

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { IDriverCard } from '../../../api/driver/interfaces';
import { DriverUtils } from '../../../utils/DriverUtils';
import { IAllDriversInitialState } from '../interfaces/IAllDrivers';

export const fetchAllDrivers = createAsyncThunk(
    'fetchAllDrivers',
    async (_, { rejectWithValue }) => {
        const driverUtils = new DriverUtils();

        try {
            const response = await associateApi.fetchAllDrivers();
            return driverUtils.formatDriverList(response.data);
        } catch {
            return rejectWithValue('Error');
        }
    },
);

const initialState: IAllDriversInitialState = {
    loading: false,
    allDrivers: [],
    filteredAllDrivers: [],
};

export const allDriversSlice = createSlice({
    name: 'fetchAllDrivers',
    initialState,
    reducers: {
        setAllDrivers: (state, action: PayloadAction<IDriverCard[]>) => {
            state.allDrivers = action.payload;
        },
        setFilteredAllDrivers: (
            state,
            action: PayloadAction<IDriverCard[]>,
        ) => {
            state.filteredAllDrivers = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAllDrivers.pending, state => {
            state.loading = true;
            state.allDrivers = [];
        });
        builder.addCase(fetchAllDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.allDrivers = action.payload;
        });
        builder.addCase(fetchAllDrivers.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const { setAllDrivers, setFilteredAllDrivers } = allDriversSlice.actions;

const AllDriversReducer = allDriversSlice.reducer;
export default AllDriversReducer;

import { Roles } from '../../utils/routes/roles';

export const HeaderConstants = {
    dashboard: {
        eventKey: '1',
        keyName: 'Navbar:dashboard',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    buildShipment: {
        eventKey: '2',
        keyName: 'Navbar:buildShipment',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    loadBoard: {
        eventKey: '3',
        keyName: 'Navbar:loadBoard',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    customer: {
        eventKey: '4',
        keyName: 'Navbar:customer',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    driver: {
        eventKey: '5',
        keyName: 'Navbar:driver',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    settings: {
        eventKey: '6',
        keyName: 'Settings:settingsPageTitle',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    userProfile: {
        eventKey: '7',
        keyName: 'LB_Common:myProfile',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
    report: {
        eventKey: '8',
        keyName: 'Navbar:report',
        roles: [Roles.FINANCE, Roles.ADMIN],
    },
    support: {
        eventKey: '9',
        keyName: 'Support:support',
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
    },
};

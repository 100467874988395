export const Routes = {
    login: '/login',
    signup: '/signup',
    logout: '/logout',
    fallback: '/fallback',
    dashboard: '/dashboard',
    accountRecovery: '/accountrecovery',
    confirmationCode: '/confirmationcode',
    resetPassword: '/reset-token',
    buildShipment: '/buildshipment',
    loadBoard: '/loadboard',
    customers: '/customers',
    brokers: '/brokers',
    drivers: '/drivers',
    shipmentDetails: '/shipmentDetails',
    settings: '/settings',
    driverDetails: '/driverdetails',
    reports: '/reports',
    support: '/support',
};

export const ConnectedRoutes = {
    dashboard: [Routes.dashboard],
    buildShipment: [
        Routes.buildShipment,
        Routes.buildShipment + Routes.shipmentDetails,
    ],
    loadBoard: [Routes.loadBoard],
    customer: [Routes.customers],
    driver: [Routes.drivers],
    shipmentDetails: [Routes.shipmentDetails],
    settings: [Routes.settings],
    reports: [Routes.reports],
    support: [Routes.support],
};

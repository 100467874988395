import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/routes';
import { dispatchManagerFeatures } from '../../featureFlags/dispatchManagerFeatures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export const Support: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isDA195885SupportPageView } = dispatchManagerFeatures;
    return (
        <>
            {isDA195885SupportPageView && (
                <div
                    className={`cursor-pointer support-icon ${
                        location.pathname.includes(Routes.support)
                            ? 'support-icon-active'
                            : ''
                    }`}
                    onClick={() => {
                        navigate(Routes.support);
                    }}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </div>
            )}
        </>
    );
};

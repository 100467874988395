import { FC, lazy } from 'react';
import { Routes } from './constants';
import { Roles } from './roles';

const Login = lazy<FC>(() => import('../../pages/Login'));
const Fallback = lazy<FC>(() => import('../../pages/Fallback'));
const Dashboard = lazy<FC>(() => import('../../pages/Dashboard'));
const AccountRecoveryModal = lazy<FC>(
    () => import('../../pages/Login/AccountRecovery'),
);
const ConfirmationCodeModal = lazy<FC>(
    () => import('../../pages/Login/ConfirmationCode'),
);
const ResetPassword = lazy<FC>(() => import('../../pages/Login/ResetPassword'));
const Customers = lazy<FC>(() => import('../../pages/Customer'));
const Drivers = lazy<FC>(() => import('../../pages/Driver'));
const BuildShipment = lazy<FC>(() => import('../../pages/BuildShipment'));
const LoadBoard = lazy<FC>(() => import('../../pages/LoadBoard'));
const ShipmentDetails = lazy<FC>(
    () => import('../../pages/LoadBoard/components/ShipmentDetails'),
);
const Settings = lazy<FC>(() => import('../../pages/Settings'));
const Reports = lazy<FC>(() => import('../../pages/Reports'));
const DriverDetails = lazy<FC>(
    () => import('../../pages/Driver/DriverDetails'),
);
const CustomerDetails = lazy<FC>(
    () => import('../../pages/Customer/components/CustomerDetails'),
);
const BrokerDetails = lazy<FC>(
    () => import('../../pages/Broker/components/BrokerDetails'),
);
const SignUp = lazy<FC>(() => import('../../pages/SignUp/index'));
const Support = lazy<FC>(() => import('../../pages/Support/Support'));

export const PUBLIC_ROUTES = [
    {
        path: Routes.login,
        component: Login,
    },
    {
        path: Routes.signup,
        component: SignUp,
    },
    {
        path: Routes.signup + '/:productFamilyFromUrl',
        component: SignUp,
    },
    {
        path: Routes.accountRecovery,
        component: AccountRecoveryModal,
    },
    {
        path: Routes.confirmationCode,
        component: ConfirmationCodeModal,
    },
    {
        path: Routes.resetPassword,
        component: ResetPassword,
    },
    {
        path: Routes.fallback,
        component: Fallback,
    },
];

export const PRIVATE_ROUTES = [
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: '/',
        component: Dashboard,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.dashboard,
        component: Dashboard,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.loadBoard,
        component: LoadBoard,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.customers,
        component: Customers,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.customers + '/:associateId',
        component: CustomerDetails,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.brokers + '/:associateId',
        component: BrokerDetails,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.drivers,
        component: Drivers,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.buildShipment,
        component: BuildShipment,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.loadBoard + Routes.shipmentDetails + '/:shipmentId',
        component: ShipmentDetails,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.settings,
        component: Settings,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.buildShipment + Routes.shipmentDetails + '/:shipmentId',
        component: BuildShipment,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.drivers + '/:associateId',
        component: DriverDetails,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.fallback,
        component: Fallback,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN],
        path: Routes.reports,
        component: Reports,
    },
    {
        roles: [Roles.FINANCE, Roles.ADMIN, Roles.DISPATCHER],
        path: Routes.support,
        component: Support,
    },
];

export { Routes };

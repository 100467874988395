import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISettingsDetails } from '../interfaces/ISettingsDetails';
import { ISetting } from '../../../api/settings/interface/ISetting';

interface ISettingsDetailInitialState {
    settingsDetail: ISettingsDetails;
    settingsList: ISetting[];
}

const initialState: ISettingsDetailInitialState = {
    settingsDetail: {
        companyName: '',
        companyAddress: '',
        phoneNumber: '',
        dispatchSupportNumber: '',
        emailAddress: '',
        companyLogo: '',
        brokerFeeType: '',
        brokerFeeAmount: '',
        faxNumber: '',
        city: '',
        state: '',
        zipCode: '',
        baseSettingId: 0,
        settlementReportBatchNumber: '',
    },
    settingsList: [],
};

export const settingsDetail = createSlice({
    name: 'settingsDetail',
    initialState: initialState,
    reducers: {
        setSettingsDetail: (state, action: PayloadAction<ISettingsDetails>) => {
            state.settingsDetail = action.payload;
        },
        setSettingsList: (state, action: PayloadAction<ISetting[]>) => {
            state.settingsList = action.payload;
        },
    },
});

export const { setSettingsDetail, setSettingsList } = settingsDetail.actions;

const settingsReducer = settingsDetail.reducer;
export default settingsReducer;

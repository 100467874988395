import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRecipientList } from '../interfaces/IRecipientList';
import { IDriverLoad } from '../../../../../../../api/loadBoard/interfaces';

interface IInvoiceState {
    invoiceLoading: boolean;
    recipients: IRecipientList;
    filteredDriverLoads: IDriverLoad[];
    isBOLRequired: boolean;
    isQuickbooksRequired: boolean;
}

const initialState: IInvoiceState = {
    invoiceLoading: false,
    recipients: {
        associateId: 0,
        contactIds: [],
        additionalRecipients: [],
    },
    filteredDriverLoads: [],
    isBOLRequired: false,
    isQuickbooksRequired: false,
};

export const invoiceModal = createSlice({
    name: 'invoiceModal',
    initialState: initialState,
    reducers: {
        setInvoiceLoading: (state, action: PayloadAction<boolean>) => {
            state.invoiceLoading = action.payload;
        },
        setSelectedRecipients: (
            state,
            action: PayloadAction<IRecipientList>,
        ) => {
            state.recipients = action.payload;
        },
        resetSelectedRecipients: state => {
            state.recipients = initialState.recipients;
        },
        setFilteredDriverLoads: (
            state,
            action: PayloadAction<IDriverLoad[]>,
        ) => {
            state.filteredDriverLoads = action.payload;
        },
        setIsBOLRequired: (state, action: PayloadAction<boolean>) => {
            state.isBOLRequired = action.payload;
        },
        setIsQuicbooksRequired: (state, action: PayloadAction<boolean>) => {
            state.isQuickbooksRequired = action.payload;
        },
    },
});

export const {
    setInvoiceLoading,
    setSelectedRecipients,
    setFilteredDriverLoads,
    setIsBOLRequired,
    resetSelectedRecipients,
    setIsQuicbooksRequired,
} = invoiceModal.actions;

const invoiceReducer = invoiceModal.reducer;
export default invoiceReducer;

import { HttpStatusCode } from 'axios';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18nInit';
import { API_CONSTANTS } from '../../services/apiConstants';
import {
    AttachmentTypes,
    AutoCloseTimeoutConstants,
} from '../../utils/constants';
import { IGenericApiResponse, IHttpCodeResponse } from '../../utils/interfaces';
import { apiCall } from '../axios';
import { IAttachmentResponse } from '../interfaces';
import { IAllDriverLoadsInvoice } from './interfaces/IAllDriverLoadsInvoice';
import { IDriverLoad } from './interfaces/IDriverLoad';

export const attachBillOfLading = async (
    shipmentId: number,
    driverLoadId: number | string,
    uploadedFile: File | undefined,
): Promise<void> => {
    const formData = new FormData();
    if (uploadedFile) {
        formData.append('File', uploadedFile);
        formData.append('Type', AttachmentTypes.billOfLading);
    }

    const response: IHttpCodeResponse = await apiCall(
        'post',
        `${API_CONSTANTS.shipments}/${shipmentId}${API_CONSTANTS.loads}/${driverLoadId}${API_CONSTANTS.attachments}`,
        formData,
        true,
        false,
        true,
    );

    if (
        response &&
        response.status >= HttpStatusCode.Ok &&
        response.status < HttpStatusCode.MultipleChoices
    ) {
        const message = i18n.t(
            'LB_AttachBillOfLading:billAttachedSuccessfully',
        );
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: AutoCloseTimeoutConstants.toasterAutoClose,
        });
    }
};

export const deleteBillOfLading = async (
    shipmentId: number | string,
    driverLoadId: number | null,
    attachmentId: number,
): Promise<void> => {
    const response = await apiCall(
        'delete',
        `${API_CONSTANTS.shipments}/${shipmentId}${API_CONSTANTS.loads}/${driverLoadId}${API_CONSTANTS.attachments}/${attachmentId}`,
        {},
        true,
    );

    return response;
};

export const createDriverLoad = async (
    shipmentId: number | string,
    loads: IDriverLoad,
): Promise<number[]> => {
    const response = await apiCall(
        'post',
        `${API_CONSTANTS.shipments}/${shipmentId}${API_CONSTANTS.loads}`,
        { ...loads },
        true,
    );

    return response.data as number[];
};

export const attachAllDriverLoadsInvoice = async (
    invoiceDetails: IAllDriverLoadsInvoice,
): Promise<void> => {
    const formData = new FormData();
    if (invoiceDetails.uploadedFile) {
        formData.append('File', invoiceDetails.uploadedFile);
        formData.append('Id', invoiceDetails.shipmentId?.toString());
        formData.append('Type', AttachmentTypes.invoice);
    }
    formData.append('IsBolRequired', invoiceDetails.isBOLRequired.toString());
    formData.append(
        'isQuickbooksRequired',
        invoiceDetails.isQuickbooksRequired.toString(),
    );
    formData.append('Recipients', JSON.stringify(invoiceDetails.recipients));

    const response: IHttpCodeResponse = await apiCall(
        'post',
        `${API_CONSTANTS.shipments}${API_CONSTANTS.invoice}`,
        formData,
        true,
        false,
        true,
    );

    if (
        response &&
        response.status >= HttpStatusCode.Ok &&
        response.status < HttpStatusCode.MultipleChoices
    ) {
        const message = i18n.t('LB_InvoiceDetails:invoiceSent');
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: AutoCloseTimeoutConstants.toasterAutoClose,
        });
    }
};

export const uploadDriverLoadAttachment = async (
    shipmentId: number,
    driverLoadId: number,
    attachment: File | null | string,
    fileType?: string,
): Promise<IAttachmentResponse> => {
    const formData = new FormData();

    attachment && formData.append('File', attachment);
    fileType && formData.append('Type', fileType);

    const response: IGenericApiResponse<IAttachmentResponse> = await apiCall(
        'post',
        `${API_CONSTANTS.shipments}/${shipmentId}${API_CONSTANTS.loads}/${driverLoadId}${API_CONSTANTS.attachments}`,
        formData,
        true,
        false,
        true,
    );

    return response.data;
};

export const updateDriverLoad = async (
    shipmentId: number | string,
    loads: IDriverLoad,
    driverLoadId: number | null,
): Promise<void> => {
    const filteredDriverLoadCharges = loads.driverLoadCharges.filter(
        charge => charge.driverLoadChargeType !== '',
    );

    const updatedLoads: IDriverLoad = {
        ...loads,
        driverLoadCharges: filteredDriverLoadCharges,
    };

    const response = await apiCall(
        'put',
        `${API_CONSTANTS.shipments}/${shipmentId}/loads/${driverLoadId}`,
        { ...updatedLoads },
        true,
    );

    return response;
};

export const deleteDriverLoad = async (
    shipmentId: number | string,
    driverLoadId: number | null,
): Promise<void> => {
    const response = await apiCall(
        'delete',
        `${API_CONSTANTS.shipments}/${shipmentId}/loads/${driverLoadId}`,
        {},
        true,
    );

    return response;
};

export const fetchDriverLoadAttachment = async (
    shipmentId: number,
    driverLoadId: number | null,
    attachment: IAttachmentResponse | null,
): Promise<IGenericApiResponse<Blob>> => {
    const response: IGenericApiResponse<Blob> = await apiCall(
        'get',
        `${API_CONSTANTS.shipments}/${shipmentId}/loads/${driverLoadId}/attachments/${attachment?.attachmentId}/${attachment?.accessToken}`,
        {},
        true,
        true,
    );

    return response;
};

const driverLoadApi = {
    createDriverLoad,
    uploadDriverLoadAttachment,
    updateDriverLoad,
    deleteDriverLoad,
    deleteBillOfLading,
    fetchDriverLoadAttachment,
};

export default driverLoadApi;

import { getFeatureFlagValue } from './helper';
import { IDispatchManagerFeatures } from './interface';

export const dispatchManagerFeatures: IDispatchManagerFeatures = {
    isDM195701ReportsPageStaticView: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DM195701_REPORTS_PAGE_STATIC_VIEW,
    ),
    isDMhideTenantSpecificQuickbooks: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DM_HIDE_TENTANT_SPECIFIC_QUICKBOOKS,
    ),
    isShowCompanyAndUsersTab: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_COMPANY_AND_USERS_TAB,
    ),
    isShowAllSettingsTabs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_ALL_SETTINGS_TABS,
    ),
    isDA195885SupportPageView: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DM195885_SUPPORT_PAGE_VIEW,
    ),
    isDA196089SettlementReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DM196089_SETTLEMENT_REPORT_FE,
    ),
    isDA196663AddAllLocationToCommodity: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DM196089_SETTLEMENT_REPORT_FE,
    ),
    isHideIntimetecMailIdUsers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_HIDE_INTIMETEC_MAIL_ID_USERS,
    ),
    isDA196728RedcueTimezoneAPICalls: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_DA196728_REDUCE_TIMEZONE_API_CALLS,
    ),
};

export const LocationDetailConstants = {
    locationTypes: {
        dropOff: 'DropOff',
        pickUp: 'Pickup',
        rescue: 'Rescue',
    },
    newLocation: {
        locationId: 0,
        locationType: 'DropOff',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        latitude: 0,
        longitude: 0,
        instructions: '',
        completeAddress: '',
    },
    oneKmInMile: 0.621371,
    markUp: 1.03,
    oneKilometer: 1000,
};

export const ShipmentTitles = [
    'Date',
    'Pick-Up',
    'Drop-Off',
    'Commodity',
    'Weight/Hd Ct',
    'Miles',
    'Rate Type',
    'Rate',
];

export const ADD_LOCATION_MODAL_MAP_ZOOM = 9;

export const LoadTypeConstants = {
    _49k: '49k:',
    _52k: '52k:',
    _55_62k: '55-62k:',
};

export const BuildShipmentTabConstants = {
    customerDetail: 'customerDetail',
    shipmentDetail: 'shipmentDetail',
};

export const BuildShipmentStatusConstants = {
    ContinueBuilding: 'ContinueBuilding',
    needDriver: 'NeedsDrivers',
};

export const CommodityDropdownKey = 'create_commodity';

export const AddLocationModalConstants = {
    modalTitle: 'BS_AddLocationModal:modalTitle',
    modalType: 'AddLocation',
};

import { AssociateTypes } from '../../../utils/constants';
import { IDriverDetailInitialState } from './interfaces/IDriverDetailInitialState';

export const DRIVER_DETAIL_INITIAL_STATE: IDriverDetailInitialState = {
    driverRecord: {
        associateType: AssociateTypes.driver,
        name: '',
        phoneNumber: '',
        email: null,
        notes: '',
        address: {
            locationId: 0,
            type: '',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
        },
        isActive: true,
        trailerNumber: '',
        truckingCoNumber: '',
        truckingCompanyId: '',
        ownerPhoneNumber: '',
        trailerSize: '',
        unitNumber: '',
        removalReason: '',
        attachments: [],
        contacts: [],
        profileImage: [],
        tagGroups: [],
        unavailabilities: [],
        isBrokenDown: false,
        isMobileAppEnabled: false,
        driverType: 'EmployeeDriver',
        ownerOperatorPercent: '',
    },
    trailer: {
        trailerSize: {
            value: '',
            label: '',
        },
    },
    driver: {
        driverStatus: {
            value: '',
            label: '',
        },
    },
    driverState: {
        value: '',
        label: '',
    },
    selectedTruckingCompany: {
        value: '',
        label: '',
    },
    isDriverStatusEditable: false,
    driverDetails: [],
    selectedDriverDetail: {
        associateType: AssociateTypes.driver,
        name: '',
        phoneNumber: '',
        email: null,
        notes: '',
        address: {
            locationId: 0,
            type: '',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
        },
        isActive: true,
        trailerNumber: '',
        truckingCoNumber: '',
        truckingCompanyId: '',
        ownerPhoneNumber: '',
        trailerSize: '',
        unitNumber: '',
        removalReason: '',
        attachments: [],
        profileImage: [],
        tagGroups: [],
        unavailabilities: [],
        isBrokenDown: false,
        contacts: [],
        isMobileAppEnabled: false,
        driverType: 'EmployeeDriver',
        ownerOperatorPercent: '',
    },
    updatedDriverDetail: {
        associateType: AssociateTypes.driver,
        name: '',
        phoneNumber: '',
        email: null,
        notes: '',
        address: {
            locationId: 0,
            type: '',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
        },
        isActive: true,
        trailerNumber: '',
        truckingCoNumber: '',
        truckingCompanyId: '',
        ownerPhoneNumber: '',
        trailerSize: '',
        unitNumber: '',
        removalReason: '',
        attachments: [],
        profileImage: [],
        tagGroups: [],
        unavailabilities: [],
        isBrokenDown: false,
        contacts: [],
        isMobileAppEnabled: false,
        driverType: 'EmployeeDriver',
        ownerOperatorPercent: '',
    },
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IDriverDetail } from '../../../../api/driver/interfaces';
import { IAttachment, ITagGroup } from '../../../../api/interfaces';
import { ISingleValueSelect } from '../../../../utils/interfaces';
import { DRIVER_DETAIL_INITIAL_STATE } from '../initialState';
import { IDriverStatus } from '../interfaces/IDriverStatus';
import { ITrailerSize } from '../interfaces/ITrailerSize';

export const driverDetailSlice = createSlice({
    name: 'driverDetail',
    initialState: DRIVER_DETAIL_INITIAL_STATE,
    reducers: {
        setDriverRecord: (
            state,
            action: PayloadAction<Partial<IDriverDetail>>,
        ) => {
            state.driverRecord = { ...state.driverRecord, ...action.payload };
        },
        setTrailerSize: (
            state,
            action: PayloadAction<Partial<ITrailerSize>>,
        ) => {
            state.trailer = {
                ...state.trailer,
                ...action.payload,
            };
        },
        setDriverStatus: (
            state,
            action: PayloadAction<Partial<IDriverStatus>>,
        ) => {
            state.driver = {
                ...state.driver,
                ...action.payload,
            };
        },
        setDriverState: (state, action: PayloadAction<ISingleValueSelect>) => {
            state.driverState = action.payload;
        },
        setSelectedTruckingCompany: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedTruckingCompany = action.payload;
        },
        setIsDriverStatusEditable: (state, action: PayloadAction<boolean>) => {
            state.isDriverStatusEditable = action.payload;
        },
        setDriverLocationTag: (state, action: PayloadAction<ITagGroup[]>) => {
            state.driverRecord.tagGroups = action.payload;
        },
        setNewDriverDetailAttachments: (
            state,
            action: PayloadAction<IAttachment[]>,
        ) => {
            state.driverRecord.attachments = action.payload;
        },
        setUpdatedDriverDetail: (
            state,
            action: PayloadAction<Partial<IDriverDetail>>,
        ) => {
            state.updatedDriverDetail = {
                ...state.updatedDriverDetail,
                ...action.payload,
            };
        },
        updateDriverDetailView: (
            state,
            action: PayloadAction<Partial<IDriverDetail>>,
        ) => {
            state.selectedDriverDetail = {
                ...state.selectedDriverDetail,
                ...action.payload,
            };
        },
        resetDriverInitialState: state => {
            state = _.cloneDeep(DRIVER_DETAIL_INITIAL_STATE);
        },
    },
});

export const {
    setDriverRecord,
    setTrailerSize,
    setDriverStatus,
    setDriverState,
    setSelectedTruckingCompany,
    setIsDriverStatusEditable,
    setDriverLocationTag,
    setNewDriverDetailAttachments,
    setUpdatedDriverDetail,
    updateDriverDetailView,
    resetDriverInitialState,
} = driverDetailSlice.actions;

const DriverDetailReducer = driverDetailSlice.reducer;
export default DriverDetailReducer;

import { FC, Suspense, useCallback, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/style/style.css';
import AppLoader from './components/AppLoader';
import i18n from './i18n/i18nInit';
import Header from './pages/Navbar';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './utils/routes';
import { useAuthentication } from './common/hooks/useAuthentication';
import { useAppDispatch } from './redux/hooks';
import { useCountryCode } from './common/countryCode/useCountryCode';
import { SettingUtils } from './pages/Settings/SettingUtils';
import {
    setSettingsDetail,
    setSettingsList,
} from './pages/Settings/reducers/settingsReducer';
import { settingsApi } from './api/settings/settingsApi';
import { fetchTagGroups } from './components/TagGroup/TagGroupReducer';
import { useUserRole } from './common/hooks/useUserRole';

export const App: FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { loadCountryCodeOptions } = useCountryCode();
    const { isAuthenticated } = useAuthentication();
    const { isProtectedRoute } = useUserRole();

    const fetchSettings = useCallback(async () => {
        const settingsDetail = new SettingUtils();
        const settings = await settingsApi.fetchSettings();
        const settingDetails = new SettingUtils().getSettingDetails(settings);
        const settingsData = await settingsDetail.fetchTenantSettings();
        dispatch(setSettingsDetail(settingsData ?? settingDetails));
        dispatch(setSettingsList(settings));
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchTagGroups());
            fetchSettings();
        }
    }, [isAuthenticated, dispatch, fetchSettings]);

    useEffect(() => {
        loadCountryCodeOptions();
    }, [loadCountryCodeOptions]);

    const isPrivateRoute =
        isAuthenticated &&
        !PUBLIC_ROUTES.find(route => route.path === location.pathname);

    return (
        <Suspense fallback={<AppLoader loadingPosition="center" />}>
            <ToastContainer />
            <I18nextProvider i18n={i18n}>
                <div className="h-100">
                    {isPrivateRoute && <Header />}
                    <div className={`${isPrivateRoute && 'routes-container'}`}>
                        <Routes>
                            {isAuthenticated
                                ? PRIVATE_ROUTES.map((privateRoute, index) => (
                                      <Route
                                          key={index}
                                          path={privateRoute.path}
                                          element={
                                              isProtectedRoute(
                                                  privateRoute.roles,
                                              ) ? (
                                                  <privateRoute.component />
                                              ) : (
                                                  <Navigate to="/" />
                                              )
                                          }
                                      />
                                  ))
                                : PUBLIC_ROUTES.map((publicRoute, index) => (
                                      <Route
                                          key={index}
                                          path={publicRoute.path}
                                          element={<publicRoute.component />}
                                      />
                                  ))}
                        </Routes>
                    </div>
                </div>
            </I18nextProvider>
        </Suspense>
    );
};

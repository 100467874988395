import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shipmentsApi } from '../../../../../../../api/shipment/shipmentsApi';
import { IInvoicePreview } from '../../../../../../../api/shipment/interfaces/IInvoicePreview';
import { INVOICE_PREVIEW_INITIAL_STATE } from '../initialState';

export const fetchInvoicePreviewDetails = createAsyncThunk(
    'fetchInvoicePreviewDetails',
    async (
        {
            shipmentId,
        }: {
            shipmentId: number;
        },
        { rejectWithValue },
    ) => {
        try {
            const response =
                await shipmentsApi.fetchShimpentInvoicePreviewDetails(
                    shipmentId,
                );
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export type IInvoicePreviewInitialState = {
    loading: boolean;
    invoicePreviewDetails: IInvoicePreview[];
};

const initialState: IInvoicePreviewInitialState = {
    loading: false,
    invoicePreviewDetails: [INVOICE_PREVIEW_INITIAL_STATE],
};

export const invoicePreviewSlice = createSlice({
    name: 'fetchInvoicePreviewDetails',
    initialState: initialState,
    reducers: {
        setInvoicePreviewDetails: (
            state,
            action: PayloadAction<IInvoicePreview[]>,
        ) => {
            state.invoicePreviewDetails = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchInvoicePreviewDetails.pending, state => {
            state.loading = true;
            state.invoicePreviewDetails = [];
        });
        builder.addCase(
            fetchInvoicePreviewDetails.fulfilled,
            (state, action) => {
                state.loading = false;
                state.invoicePreviewDetails = action.payload;
            },
        );
        builder.addCase(fetchInvoicePreviewDetails.rejected, state => {
            state.loading = false;
            state.invoicePreviewDetails = [INVOICE_PREVIEW_INITIAL_STATE];
        });
    },
});

export const { setInvoicePreviewDetails } = invoicePreviewSlice.actions;

const InvoicePreviewReducer = invoicePreviewSlice.reducer;
export default InvoicePreviewReducer;
